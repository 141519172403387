import { set } from 'lodash';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SERVICE_PAGE_NAME } from '../constants';
import {
  BookingsQueryParams,
  getDateRegionalSettingsLocale,
  getLanguage,
  getLocale,
  getServiceSlug,
  getUrlQueryParamValue,
  trackAnalytics,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { BookingsApi } from '../../../api/BookingsApi';
import { ACTION_NAMES, biDefaults, generateWidgetDefaults } from '../bi/consts';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { Schedule } from '@wix/ambassador-schedule-server/types';
import { isCourseService } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { BusinessInfo, TimezoneType } from '@wix/bookings-uou-types';
import { initializeViewModels } from './initialize-view-models';
import {
  bookingsBookItClick,
  bookingsCantBookGroupsMessage,
  bookingsServicePageDrpodownChangeLocationSelection,
  bookingsServicesPageView,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  handleNavigation,
  NavigateToCalendarAction,
} from './handle-navigation';
import {
  GetActiveFeaturesResponse,
  GetPropertiesResponse,
} from '@wix/ambassador-services-catalog-server/types';
import { initUserMessage } from './init-user-message';
import { schedulingLocationViewModelFactory } from '../../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import {
  dummySchedulingViewModel,
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
  schedulingSectionViewModelFactory,
} from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { getServiceSchedulingData } from './scheduling-fetcher';
import {
  SchedulingTimezoneViewModel,
  schedulingTimezoneViewModelFactory,
} from '../../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../../service-page-view-model/servicePageViewModel';
import { CourseAvailabilityDisplay } from '../../../service-page-view-model/details-section-view-model/detailsSectionViewModel';
import { BookingsServicesPageViewParams } from '../types';
import { getTrackingInfoForServicePageLoads } from '@wix/bookings-calendar-catalog-viewer-analytics-adapter';
import { mapServiceToBookingPolicy } from '../../../mappers/booking-policy.mapper';
import { mapResponseToBusinessInfo } from '../../../mappers/bussines-info.mapper';
import { getConfig } from '../../../api/config.api';
import { CatalogSessionDto, WidgetConfig } from '../../../types/shared-types';
import { mapServiceToServiceV2 } from '../../../mappers/service-v2.mapper';
import {
  createOpenServicesPreferencesModalAction,
  OpenServicesPreferencesModalAction,
} from './servicesPreferencesModal/openServicesPreferencesModal';
import { createOnPreferencesModalServiceSelectedAction } from './servicesPreferencesModal/onPreferencesModalServiceSelected';
import type { Basket } from '@wix/bookings-services-preferences-modal/types';
import { ServicePageActions } from '../actions/actions';

export const initializeWidget = async ({
  flowAPI,
  isBookingCalendarInstalled,
  isBookingFormInstalled,
  settings,
}: {
  flowAPI: ControllerFlowAPI;
  isBookingCalendarInstalled: boolean;
  isBookingFormInstalled: boolean;
  settings: any;
}) => {
  const { reportError, experiments } = flowAPI;
  const setProps = flowAPI.controllerConfig.setProps;
  const { isViewer, isPreview, isEditor, isSSR } = flowAPI.environment;
  const { httpClient } = flowAPI;
  const bookingsApi = new BookingsApi({
    flowAPI,
  });
  const { platformAPIs, appParams, wixCodeApi } = flowAPI.controllerConfig;
  let isEditorX = false;
  const isWixStudio =
    flowAPI.controllerConfig.wixCodeApi?.location?.query?.dsOrigin === 'studio';
  let scheduleViewModel: SchedulingSectionViewModel = {
    status: SchedulingSectionStatus.LOADING,
    isBookable: false,
  };
  async function fetchWidgetConfig(serviceSlug) {
    return (
      await httpClient.request({
        ...getConfig(serviceSlug, isPreview),
        headers: { 'x-time-budget': '10000' },
      })
    ).data;
  }

  let viewModel: ServicePageViewModel;
  let catalogSessionsDto: CatalogSessionDto[] | undefined;
  let getServiceSchedulingDataByLocation;
  let navigateToCalendar: NavigateToCalendarAction = async () => {};
  let changeTimezoneCallback: any = () => {};
  let openServicesPreferencesModal: OpenServicesPreferencesModalAction =
    async () => {};
  let onPreferencesModalServiceSelected = (...args: any[]) => {};
  let service: Service | undefined;
  let businessInfo: BusinessInfo | undefined;
  let locationViewModel;
  let widgetConfig: WidgetConfig | null = null;
  if (isViewer || isPreview) {
    const serviceSlug = await getServiceSlug({
      wixCodeApi,
      pageName: SERVICE_PAGE_NAME,
      useCustomUrlSegments: experiments.enabled(
        'specs.bookings.useCustomUrlSegmentsForSlug',
      ),
    });
    let schedule: Schedule | undefined;
    let activeFeatures: GetActiveFeaturesResponse | undefined;
    let businessProperties: GetPropertiesResponse | undefined;

    try {
      if (experiments.enabled('specs.bookings.ServiceV2ServicePage')) {
        const [servicesResponse, businessInfoResponse] = await Promise.all([
          bookingsApi.queryServicesBySlug({ serviceSlug }),
          bookingsApi.getBusinessInfo().catch((e) => {
            console.error('Failed to fetch business info', e);
            return undefined;
          }),
        ]);
        service = servicesResponse;
        businessInfo = mapResponseToBusinessInfo(businessInfoResponse);
        activeFeatures = businessInfoResponse?.activeFeatures;
        businessProperties = businessInfoResponse?.businessProperties;

        if (service && isCourseService(service)) {
          schedule = await bookingsApi.listSchedule(service.id!);
        }
      } else {
        widgetConfig = await fetchWidgetConfig(serviceSlug);
        service = widgetConfig?.serviceDto
          ? mapServiceToServiceV2({
              service: widgetConfig?.serviceDto!,
              serviceResponse: widgetConfig?.SEO?.serviceResponse!,
            })
          : undefined;
        businessInfo = widgetConfig.businessInfo;
        activeFeatures = widgetConfig.activeFeaturesResponse;
      }
    } catch (e) {
      console.error('Failed to fetch service', e);
    }

    if (!service) {
      wixCodeApi.seo.setSeoStatusCode(404);
      setProps({ fitToContentHeight: true });
      return;
    }

    const isMultiServiceAppointmentEnabled =
      !!businessProperties?.customProperties?.some(
        ({ propertyName, value }) =>
          propertyName === 'isMultiServicesAppointmentsEnable' &&
          value === 'true',
      );

    const referralInfo = getUrlQueryParamValue(
      wixCodeApi,
      BookingsQueryParams.REFERRAL,
    );

    flowAPI.bi?.updateDefaults({
      ...biDefaults,
      ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
      serviceId: service?.id,
      service_id: service?.id,
      type: service?.type,
      referralInfo,
    });

    const bookingsPolicy = experiments.enabled(
      'specs.bookings.ServiceV2ServicePage',
    )
      ? mapServiceToBookingPolicy(service, schedule)
      : widgetConfig?.bookingPolicyDto;

    if (!experiments.enabled('specs.bookings.ServiceV2ServicePage')) {
      await wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.BOOKINGS_SERVICE,
        itemData: {
          serviceResponse: widgetConfig?.SEO.serviceResponse,
          bookingsPolicyDto: widgetConfig?.bookingPolicyDto,
        },
        seoData: widgetConfig?.SEO.serviceResponse?.service?.advancedSeoData,
      });
    } else if (service) {
      await wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.BOOKINGS_SERVICE,
        itemData: {
          service,
          bookingsPolicy,
        },
        seoData: service.seoData,
      });
    }

    const queryLocationId = wixCodeApi.location.query?.location;
    const selectedLocation = service.locations?.find((location) => {
      return location?.business?.id === queryLocationId;
    })
      ? queryLocationId
      : undefined;

    if (
      experiments.enabled(
        'specs.bookings.getLocaleAndLanguageFromYoshiInServicePage',
      ) &&
      businessInfo
    ) {
      businessInfo.language = getLanguage(wixCodeApi);
      businessInfo.regionalSettingsIntlLocale = getLocale(wixCodeApi);
      businessInfo.regionalSettingsLocale = getLocale(wixCodeApi);
      businessInfo.dateRegionalSettingsLocale =
        getDateRegionalSettingsLocale(wixCodeApi);
    }

    const initViewModels = initializeViewModels({
      service,
      businessInfo,
      bookingsPolicy,
      selectedLocation,
      isBookingCalendarInstalled,
      settings,
      flowAPI,
      isMultiServiceAppointmentEnabled,
    });
    viewModel = initViewModels.viewModel;
    locationViewModel = initViewModels.locationViewModel;

    let timezoneViewModel: SchedulingTimezoneViewModel =
      initViewModels.timezoneViewModel;

    const getActionName = async () => {
      if (isCourseService(service!)) {
        return isBookingFormInstalled
          ? ACTION_NAMES.NAVIGATE_TO_BOOKING_FORM
          : ACTION_NAMES.NAVIGATE_TO_CONTACT_FORM;
      } else {
        return isBookingCalendarInstalled
          ? ACTION_NAMES.NAVIGATE_TO_CALENDAR
          : undefined;
      }
    };

    changeTimezoneCallback = (timezoneType: TimezoneType) => {
      timezoneViewModel = schedulingTimezoneViewModelFactory({
        businessInfo,
        selectedTimezoneType: timezoneType,
        isBookingCalendarInstalled,
      });
      viewModel = servicePageViewModelFactory({
        service: service!,
        businessInfo,
        bookingsPolicy: bookingsPolicy!,
        t: flowAPI.translations.t,
        viewTimezone: timezoneViewModel.viewTimezone,
        settings,
        experiments,
        isMultiServiceAppointmentEnabled,
      });
      scheduleViewModel = schedulingSectionViewModelFactory({
        isBookable: viewModel.body.isBookable,
        catalogSessionsDto,
        businessInfo: businessInfo!,
        viewTimezone: timezoneViewModel.viewTimezone,
        isCourse: isCourseService(service!),
        firstSessionStart: service?.schedule?.firstSessionStart?.toUTCString(),
        lastSessionEnd: service?.schedule?.lastSessionEnd?.toUTCString(),
        t: flowAPI.translations.t,
      });

      setProps({
        viewModel,
        timezoneViewModel,
        scheduleViewModel,
      });
    };

    openServicesPreferencesModal = createOpenServicesPreferencesModalAction({
      flowAPI,
      service,
      viewModel,
      businessInfo: businessInfo!,
      isMultiServiceAppointmentEnabled,
    });

    onPreferencesModalServiceSelected =
      createOnPreferencesModalServiceSelectedAction({ flowAPI, viewModel });

    navigateToCalendar = async ({
      basket,
      initiatedBy,
    }: { initiatedBy?: string; basket?: Basket } = {}) => {
      flowAPI.bi?.report(
        bookingsBookItClick({
          section: initiatedBy,
          actionName: await getActionName(),
          businessId: undefined,
        }),
      );

      set(viewModel, 'button.navigationInitiatedBy', initiatedBy);
      setProps({ viewModel });

      return handleNavigation({
        service: service!,
        businessInfo,
        activeFeatures,
        locationId: locationViewModel.currentLocation,
        timezone: timezoneViewModel.viewTimezone,
        flowAPI,
        basket,
        onNavigationFailed: async ({ failReasons }) => {
          const { sendNotification } = await import('./sendNotification');

          sendNotification({
            appParams,
            failReasons,
            serviceV2: service,
            wixCodeApi,
          });
          flowAPI.bi?.report(
            bookingsCantBookGroupsMessage({
              widget_name: 'service_page',
              referralInfo: 'service_page',
              isPreview,
              failReason: JSON.stringify(failReasons),
            }),
          );
          showUserMessage();

          set(viewModel, 'button.navigationInitiatedBy', '');
          setProps({ viewModel });
        },
      });
    };

    getServiceSchedulingDataByLocation = (locationId: string) => {
      locationViewModel = schedulingLocationViewModelFactory({
        service: service!,
        selectedLocation: locationId,
        t: flowAPI.translations.t,
      });
      scheduleViewModel = {
        status: SchedulingSectionStatus.LOADING,
        isBookable: viewModel.body.isBookable,
      };
      setProps({
        locationViewModel,
        scheduleViewModel,
        fitToContentHeight: true,
      });

      const handleScheduleError = (error: any) => {
        reportError(error);
        scheduleViewModel = {
          status: SchedulingSectionStatus.FAILED,
          isBookable: false,
        };
        setProps({
          scheduleViewModel,
        });
      };
      if (!businessInfo) {
        handleScheduleError(new Error('Business info is missing'));
        return;
      }
      if (
        !experiments.enabled('specs.bookings.react18ErrorInServicePge') ||
        !isSSR
      ) {
        getServiceSchedulingData({
          service,
          settings,
          httpClient,
          locationId,
          isIncreaseServicePageTimeBudget: true,
        })
          .then((serviceSchedule) => {
            catalogSessionsDto = serviceSchedule?.sessions;

            scheduleViewModel = schedulingSectionViewModelFactory({
              isBookable: viewModel.body.isBookable,
              catalogSessionsDto,
              businessInfo: businessInfo!,
              viewTimezone: timezoneViewModel.viewTimezone,
              isCourse: isCourseService(service!),
              firstSessionStart:
                service?.schedule?.firstSessionStart?.toUTCString(),
              lastSessionEnd: service?.schedule?.lastSessionEnd?.toUTCString(),
              t: flowAPI.translations.t,
            });
            setProps({
              locationViewModel,
              scheduleViewModel,
              timezoneViewModel,
            });
          })
          .catch(handleScheduleError);
      }
    };
    if (
      !isSSR ||
      experiments.enabled('specs.bookings.react18ErrorInServicePge')
    ) {
      void getServiceSchedulingDataByLocation(
        locationViewModel.currentLocation!,
      );
    }
    const trackingInfo = getTrackingInfoForServicePageLoads({
      service,
      businessName: businessInfo?.name || '',
    });

    trackAnalytics({
      wixCodeApi,
      eventId: trackingInfo.eventId as any,
      payload: trackingInfo.payload,
    });
  } else {
    isEditorX =
      flowAPI.controllerConfig.config.style.styleParams.booleans.responsive;
    const courseAvailability =
      (settings.courseAvailability &&
        (settings.displayNumberOfSpots
          ? CourseAvailabilityDisplay.NUMBER_OF_SPOTS
          : CourseAvailabilityDisplay.AVAILABILITY)) ||
      undefined;
    viewModel = dummyViewModelFactory({
      t: flowAPI.translations.t,
      isEditorX,
      isWixStudio:
        flowAPI.experiments.enabled(
          'specs.bookings.useSkeletonImagesInWixStudio',
        ) && isWixStudio,
      courseAvailability,
    });
    const dummyBusinessInfo = {
      timeZone: 'UTC',
      regionalSettingsLocale: flowAPI.environment.language,
      dateRegionalSettingsLocale: flowAPI.environment.language,
    };
    scheduleViewModel = dummySchedulingViewModel({
      t: flowAPI.translations.t,
      businessInfo: dummyBusinessInfo,
      scheduleDays: settings.scheduleDays,
    });

    flowAPI.bi?.updateDefaults({
      ...biDefaults,
      ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
    });
  }
  const reportPageLoaded = (params: BookingsServicesPageViewParams) =>
    flowAPI.bi?.report(bookingsServicesPageView(params));

  const changeLocationCallback = (locationGuid: string) => {
    if (!getServiceSchedulingDataByLocation) {
      return;
    }

    flowAPI.bi!.report(
      bookingsServicePageDrpodownChangeLocationSelection({
        locationGuid,
      }),
    );

    getServiceSchedulingDataByLocation(locationGuid);
  };
  const { userMessage, showUserMessage } = initUserMessage(setProps);

  const actions: ServicePageActions = {
    navigateToCalendar,
    openServicesPreferencesModal,
    onPreferencesModalServiceSelected,
    changeLocationCallback,
    reportPageLoaded,
    changeTimezoneCallback,
    closeDialog: () => {
      viewModel.dialogViewModel.isOpen = false;
      setProps({ viewModel: { ...viewModel } });
    },
  };

  setProps({
    actions,
    scheduleViewModel,
    locationViewModel,
    viewModel,
    userMessage,
    fitToContentHeight: true,
  });
  return service;
};
