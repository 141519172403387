import { FailReasons } from '../constants';
import { biDefaults } from '../bi/consts';
import { BusinessInfo } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import {
  isDemoMode,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
  isTemplateMode,
  trackAnalytics,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  isCourseService,
  isFeatureEnabled,
  isServiceConnectedToPricingPlan,
  isServiceHasPricingPlanError,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { getTrackingInfoForBookButtonClick } from '@wix/bookings-calendar-catalog-viewer-analytics-adapter';
import type { Basket } from '@wix/bookings-services-preferences-modal/types';

export type NavigateToCalendarAction = (args?: {
  initiatedBy?: string;
  basket?: Basket;
}) => Promise<void>;

export const handleNavigation = async ({
  service,
  businessInfo,
  activeFeatures,
  onNavigationFailed,
  locationId,
  timezone,
  flowAPI,
  basket,
}: {
  service: Service;
  businessInfo?: BusinessInfo;
  activeFeatures?: GetActiveFeaturesResponse;
  onNavigationFailed: ({ failReasons }: { failReasons: FailReasons[] }) => void;
  locationId?: string;
  timezone?: string;
  flowAPI: ControllerFlowAPI;
  basket?: Basket;
}) => {
  const { isPreview } = flowAPI.environment;
  const featureEnabled = activeFeatures
    ? isFeatureEnabled({ activeFeatures, service })
    : true;
  const isPricingPlanInstalled = await isPricingPlanInstalledUtils(
    flowAPI.controllerConfig.wixCodeApi,
  );

  if (
    isPreview ||
    isDemoMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    }) ||
    isTemplateMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    }) ||
    (featureEnabled &&
      (await isPricingPlanNavigateEnabled(isPricingPlanInstalled, service)))
  ) {
    const referral = biDefaults.service_page_referral_info;
    const serviceSlug = service!.mainSlug?.name!;
    const isCourse = isCourseService(service);

    if (service) {
      const trackingInfo = getTrackingInfoForBookButtonClick({
        service,
        businessName: businessInfo?.name || '',
      });

      trackAnalytics({
        wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
        eventId: trackingInfo.eventId as any,
        payload: trackingInfo.payload,
      });
    }

    const serviceId = service.id!;
    if (isCourse) {
      navigateToBookingsFormPage({
        platformAPIs: flowAPI.controllerConfig.platformAPIs,
        wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
        serviceId,
        serviceSlug,
        queryParams: { referral, timezone },
      });
    } else {
      navigateToBookingsCalendarPage(
        flowAPI.controllerConfig.wixCodeApi,
        flowAPI.controllerConfig.platformAPIs,
        {
          serviceSlugOrBasket: basket || serviceSlug,
          navigationContext: {
            referral,
            location: locationId,
            timezone,
          },
        },
      );
    }
  } else {
    if (!featureEnabled) {
      onNavigationFailed({ failReasons: [FailReasons.Premium] });
    } else {
      const failReasons: FailReasons[] = [];
      if (!isPricingPlanInstalled) {
        failReasons.push(FailReasons.PricingPlanNotInstalled);
      }
      if (!isServiceConnectedToPricingPlan({ payment: service.payment! })) {
        failReasons.push(FailReasons.NoPlansAssignedToService);
      }
      onNavigationFailed({ failReasons });
    }
  }
};

const isPricingPlanNavigateEnabled = async (
  isPricingPlanInstalled: boolean,
  service: Service,
) => {
  return !isServiceHasPricingPlanError({
    payment: service.payment!,
    isPricingPlanInstalled,
  });
};
